/* eslint-disable */
import React, { useContext } from 'react';
import { Link } from 'gatsby';
import LazyLoad from 'react-lazyload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
// import { faChevronRight } from '@witbe/pro-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { useTranslation } from 'react-i18next';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import cssCareers from '../../pages/styles/careers.module.scss';
import styles from './styles/mobileCareers.module.scss';
import MobileBanner from '../../images/careers/careers-mobile-hero.svg';
import PTO from '../../images/careers/new/paid-time-off.jpg';
import healthInsurance from '../../images/careers/new/health-and-wellness.jpg';
import officePerks from '../../images/careers/new/office-culture.jpg';
import popularLocations from '../../images/careers/locations.png';
import techStyles from '../../pages/styles/newTechnology.module.scss';
import ogimagemobile from '../../images/careers/new/careers-mobile-hero.svg';
import homeStyles from '../../pages/styles/newHome.module.scss';
import careerStyles from '../../pages/styles/newCareers.module.scss';

export default function MobileCareers() {
    const { t } = useTranslation()

    return (
        <>
            <div id={techStyles.mainImageWrapper}>
                <div className={techStyles.mainImageTextWrapper}>
                    <div className={techStyles.mainImageLeft}>
                        <div className={careerStyles.mainImageContentMobile}>
                            <h1 className={techStyles.mainImageTitle}>{t('siteMetadata.careers.title')}</h1>
                            <p className={techStyles.mainImageSub}>{t('siteMetadata.careers.subtitle')}</p>
                            <div className={homeStyles.witboxButtons}>
                                <a
                                    target='_blank'
                                    rel='noreferrer'
                                    href={'https://www.linkedin.com/jobs/search/?f_C=61604&geoId=92000000'}
                                    className={homeStyles.blueButton}
                                >
                                        {t('siteMetadata.careers.see')}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={techStyles.mainImageRight}>
                        <div className={techStyles.rightImageWrapper}>
                            <img src={ogimagemobile} alt='Banner' />
                        </div>
                    </div>
                </div>
            </div>
            <section id={techStyles.whiteSection}>
                <h2 id={techStyles.whiteSectionTitle}>
                    {t(`siteMetadata.careers.story`)}
                </h2>
                <div className={techStyles.whiteSectionText}>
                    <p>{t(`siteMetadata.careers.storySub`)}</p>
                    <div className={homeStyles.learnMoreBuyButtons}>
                        <Link
                            to='/about/'
                            className={careerStyles.cardLearnMoreInline}
                        >
                            {t('siteMetadata.careers.learnAbout')}
                        </Link>
                        <FontAwesomeIcon
                            icon={faChevronRight}
                            className={homeStyles.chevronRight}
                        />
                    </div>
                </div>
            </section>
            <section id={techStyles.quadColSectionMobile}>
                        <h2 id={techStyles.quadColTitleMobile}>
                            {t('siteMetadata.careers.keyBenefits')}
                        </h2>
                        <ul id={techStyles.quadColListMobile}>
                                <li
                                    className={careerStyles.itemMobile}
                                >
                                    <img src={PTO} alt='Paid time off' />
                                    <div className={careerStyles.itemContentMobile}>
                                        <h3 className={careerStyles.itemTitleMobile}>{t('siteMetadata.careers.paidTimeOff')}</h3>
                                        <p className={careerStyles.itemParagraphMobile}>{t('siteMetadata.careers.paidTimeOffSub')}</p>
                                    </div>
                                </li>
                                <li
                                    className={careerStyles.itemMobile}
                                >
                                    <img src={healthInsurance} alt='Health and wellness' />
                                    <div className={careerStyles.itemContentMobile}>
                                        <h3 className={careerStyles.itemTitleMobile}>{t('siteMetadata.careers.healthInsurance')}</h3>
                                        <p className={careerStyles.itemParagraphMobile}>{t('siteMetadata.careers.healthInsuranceSub')}</p>
                                    </div>
                                </li>
                                <li
                                    className={careerStyles.itemMobile}
                                >
                                    <img src={officePerks} alt='Office culture' />
                                    <div className={careerStyles.itemContentMobile}>
                                        <h3 className={careerStyles.itemTitleMobile}>{t('siteMetadata.careers.officePerks')}</h3>
                                        <p className={careerStyles.itemParagraphMobile}>{t('siteMetadata.careers.officePerksSub')}</p>
                                    </div>
                                </li>
                                <Link
                                    to='/locations/'
                                >
                                    <li
                                        className={careerStyles.itemMobile}
                                    >
                                        <img src={popularLocations} alt='Popular locations' />
                                        <div className={careerStyles.itemContentMobile}>
                                            <h3 className={careerStyles.itemTitleMobile}>{t('siteMetadata.careers.locations')}</h3>
                                            <p className={careerStyles.itemParagraphMobile}>{t('siteMetadata.careers.locationsSub')}</p>
                                            <p className={careerStyles.itemLearnMobile}>
                                                {t('siteMetadata.aboutPage.learnMore')}
                                                <FontAwesomeIcon
                                                    icon={faChevronRight}
                                                    className={techStyles.chevronRightBlue}
                                                />
                                            </p>
                                        </div>
                                    </li>
                                </Link>
                        </ul>
                    </section>
        </>
    )
}
