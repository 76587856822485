import React from 'react';
import { Layout } from '../components/common';
import ogimage from '../images/careers/careers_main.png';
import SEO from '../components/common/SEO';
import { useViewport } from '../hooks';
import MobileCareers from '../components/common/MobileCareers';
import DesktopCareers from '../components/common/DesktopCareers';

export default function Careers() {
    const { width } = useViewport();
    const breakpoint = 500;

    return (
        <Layout title='Careers - Witbe'>
            <SEO
                title='Careers - Witbe'
                ogDescription='Witbe has offices in more than 7 countries. Learn about our history, open positions, and employee benefits. Join us and become a part of our adventure!'
                description='Witbe has offices in more than 7 countries. Learn about our history, open positions, and employee benefits. Join us and become a part of our adventure!'
                image={ogimage}
            />

            {width < breakpoint ? <MobileCareers /> : <DesktopCareers />}

        </Layout>
    );
}
