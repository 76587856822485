/* eslint-disable */
import React, { useContext } from 'react';
import LazyLoad from 'react-lazyload';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
// import { faChevronRight } from '@witbe/pro-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { useTranslation } from 'react-i18next';
import styles from '../../pages/styles/careers.module.scss';
// import Button from '@witbe/ui-react-components/dist/Button';
import Banner from '../../images/careers/careers-hero.svg';
import PTO from '../../images/careers/new/paid-time-off.jpg';
import healthInsurance from '../../images/careers/new/health-and-wellness.jpg';
import officePerks from '../../images/careers/new/office-culture.jpg';
import popularLocations from '../../images/careers/locations.png';
import { useViewport } from '../../hooks';
import techStyles from '../../pages/styles/newTechnology.module.scss';
import ogimagemobile from '../../images/careers/new/careers-mobile-hero.svg';
import ogimage from '../../images/careers/new/careers-hero.svg';
import aboutStyles from '../../pages/styles/about.module.scss';
import productStyles from '../../pages/styles/products.module.scss';
import homeStyles from '../../pages/styles/newHome.module.scss';
import witboxStyles from '../../pages/styles/witbox.module.scss';
import careerStyles from '../../pages/styles/newCareers.module.scss';

export default function DesktopCareers() {
    const { t } = useTranslation()

    const { width } = useViewport();
    const breakpointTablets = 821;

    return (
        <>
            <div id={techStyles.mainImageWrapper}>
                {width < breakpointTablets ?
                    <div className={techStyles.mainImageTextWrapper}>
                        <div className={techStyles.mainImageLeft}>
                            <div className={careerStyles.mainImageContentMobile}>
                                <h1 className={techStyles.mainImageTitle}>{t('siteMetadata.careers.title')}</h1>
                                <p className={techStyles.mainImageSub}>{t('siteMetadata.careers.subtitle')}</p>
                                <div className={careerStyles.witboxButtons}>
                                    <a
                                        className={homeStyles.blueButton}
                                        target='_blank'
                                        rel='noreferrer'
                                        href={'https://www.linkedin.com/jobs/search/?f_C=61604&geoId=92000000'}
                                    >
                                        {t('siteMetadata.careers.see')}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className={techStyles.mainImageRight}>
                            <div className={techStyles.rightImageWrapper}>
                                <img src={ogimagemobile} alt='Banner' />
                            </div>
                        </div>
                    </div>
                    :
                    <LazyLoad className={techStyles.mainImageTextWrapperBlue}>
                        <img src={ogimage} id={techStyles.mainImg} alt='Banner' />
                        <div className={witboxStyles.mainImageContent}>
                            <h1 className={techStyles.mainImageTitle}>{t('siteMetadata.careers.title')}</h1>
                            <p className={techStyles.mainImageSub}>{t('siteMetadata.careers.subtitle')}</p>
                            <div className={techStyles.learnMoreBuyButtons}>
                                <a
                                    className={homeStyles.transparentButton + ' ' + homeStyles.buyButton}
                                    target='_blank'
                                    rel='noreferrer'
                                    href={'https://www.linkedin.com/jobs/search/?f_C=61604&geoId=92000000'}
                                >
                                    {t('siteMetadata.careers.see')}
                                </a>
                            </div>
                        </div>
                    </LazyLoad>
                }
            </div>
            <section id={careerStyles.whiteSection}>
                <h2 id={techStyles.whiteSectionTitle}>
                    {t(`siteMetadata.careers.witwho`)}
                </h2>
                <div className={techStyles.whiteSectionText}>
                    <p>{t(`siteMetadata.careers.witwhoSub`)}</p>
                    <div className={careerStyles.learnMoreBuyButtons}>
                        <Link
                            to='/about/'
                            className={careerStyles.cardLearnMoreInline}
                        >
                            {t('siteMetadata.careers.learnAbout')}
                        </Link>
                        <FontAwesomeIcon
                            icon={faChevronRight}
                            className={careerStyles.chevronRight}
                        />
                    </div>
                </div>
            </section>
            <section id={techStyles.quadColSection}>
                        <h2 id={techStyles.quadColTitle}>
                            {t('siteMetadata.careers.keyBenefits')}
                        </h2>
                        <ul id={techStyles.quadColList}>
                                <li className={careerStyles.itemNoHover}>
                                    <div className={careerStyles.itemNoHoverThumb}>
                                        <img src={PTO} className={careerStyles.itemNoHoverIcon} alt='Paid time off' />
                                    </div>
                                    <div className={careerStyles.itemNoHoverText}>
                                        <h3 className={careerStyles.itemNoHoverTitle}>{t('siteMetadata.careers.paidTimeOff')}</h3>
                                        <p className={careerStyles.itemNoHoverParagraph}>{t('siteMetadata.careers.paidTimeOffSub')}</p>
                                    </div>
                                </li>
                                <li className={careerStyles.itemNoHover}>
                                    <div className={careerStyles.itemNoHoverThumb}>
                                            <img src={healthInsurance} className={careerStyles.itemNoHoverIcon} alt='Health and wellness' />
                                    </div>
                                    <div className={careerStyles.itemNoHoverText}>
                                        <h3 className={careerStyles.itemNoHoverTitle}>
                                            {t('siteMetadata.careers.healthInsurance')}
                                        </h3>
                                        <p className={careerStyles.itemNoHoverParagraph}>{t('siteMetadata.careers.healthInsuranceSub')}</p>
                                    </div>
                                </li>
                                <li className={careerStyles.itemNoHover}>
                                    <div className={careerStyles.itemNoHoverThumb}>
                                        <img src={officePerks} className={careerStyles.itemNoHoverIcon} alt='Office culture' />
                                    </div>
                                    <div className={careerStyles.itemNoHoverText}>
                                        <h3 className={careerStyles.itemNoHoverTitle}>
                                            {t('siteMetadata.careers.officePerks')}
                                        </h3>
                                        <p className={careerStyles.itemNoHoverParagraph}>{t('siteMetadata.careers.officePerksSub')}</p>
                                    </div>
                                </li>
                                <Link
                                    to='/locations/'
                                >
                                    <li className={techStyles.item}>
                                        <div className={techStyles.itemThumb}>
                                            <img src={popularLocations} className={techStyles.itemIcon} alt='Popular locations' />
                                        </div>
                                        <div className={techStyles.itemText}>
                                            <h3 className={techStyles.itemTitle}>
                                                {t('siteMetadata.careers.locations')}
                                            </h3>
                                            <p className={techStyles.itemParagraph}>{t('siteMetadata.careers.locationsSub')}</p>
                                            <p className={techStyles.itemLearnMore}>
                                            {t('siteMetadata.aboutPage.learnMore')}
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={techStyles.chevronRightBlue}
                                            />
                                        </p>
                                        </div>
                                    </li>
                                </Link>

                        </ul>
                    </section>
        </>
    )
}
